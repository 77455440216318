import React from 'react'
import { Fade } from "react-awesome-reveal";
export default function NotFound() {
    return (
        <div>
            <Fade>
                <h1>Error 404, página no encontrada</h1>

            </Fade>
        </div>
    )
}
